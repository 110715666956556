import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TwoColumnLayout, SplashPage, Redirect } from '../../components/Layout'
import { Helmet } from 'react-helmet'
import GoBackButton from './_components/GoBackButton'
import Container from './_components/Container'
import Title from './_components/Title'
import Description from './_components/Description'
import { navigate } from 'gatsby'
import ROUTES from '../../config/routes'
import LangPicker from '../../components/LanguagePicker'
import resetImagePNG from '../../static/eastwood/eastwood-doberman-lies-near-the-house.png'
import lonelySecurityPNG from '../../static/eastwood/lonely-security.png'
import Link from '../../components/Link/GatsbyLink'
import useAuth from '../../state/auth/useAuth'
import ResetPassword from '../../components/Form/ResetPassword'
import ChangePassword from '../../components/Form/ChangePassword'
import { useEffect, useState } from 'react'
import { notificationSuccess } from '../../utils/notification'

const PasswordResetPage = () => {
  const { t } = useTranslation('pages')
  const { t: tf } = useTranslation('forms')

  const { isLoaded, isGuest } = useAuth()
  const [token, setToken] = useState(false)

  const isTokenLoaded = token !== false
  const isTokenPresent = isTokenLoaded && token !== ''

  useEffect(() => {
    const params = new URL(window.document.location).searchParams
    setToken(params.get('q') ? params.get('q') : '')
  }, [])

  if (isLoaded === false || isTokenLoaded === false) {
    return <SplashPage />
  }

  if (isGuest === false) {
    return <Redirect to={ROUTES.home} />
  }

  return (
    <TwoColumnLayout
      leftClassnames={'bg-white'}
      rightClassnames={'bg-gray-200'}
      rightChildren={
        <div>
          {isTokenPresent === false && (
            <Link
              to={ROUTES.signin}
              className={
                'btn btn-sm btn-primary-outline w-24 absolute right-0 top-0 mr-5 lg:mr-8 mt-5 lg:mt-8 z-10'
              }
            >
              {t('reset.loginLink')}
            </Link>
          )}

          {isTokenPresent ? (
            <img
              src={lonelySecurityPNG}
              alt={'Change Password - Academator'}
              className={'w-100 absolute bottom-0 mb-16'}
            />
          ) : (
            <img
              src={resetImagePNG}
              alt={'Password Reset - Academator'}
              className={'w-100 absolute bottom-0 mb-12'}
            />
          )}

          <LangPicker
            containerClass={'absolute bottom-0 right-0 mb-12 mr-12 text-sm text-gray-700'}
          />
        </div>
      }
    >
      <Helmet>
        <title>{t('reset.seo.title')}</title>
      </Helmet>

      <GoBackButton onClick={() => navigate(ROUTES.goBack)} />

      {isTokenPresent === false && (
        <Link
          to={ROUTES.signin}
          className={
            'btn btn-sm btn-primary-outline w-24 absolute right-0 top-0 mr-5 lg:mr-8 mt-5 lg:mt-8 lg:hidden'
          }
        >
          {t('reset.loginLink')}
        </Link>
      )}

      <Container>
        <Title>{isTokenPresent ? t('reset.changePasswordTitle') : t('reset.title')}</Title>
        <Description>
          {isTokenPresent ? t('reset.changePasswordSubtitle') : t('reset.subtitle')}
        </Description>

        {isTokenPresent ? (
          <ChangePassword
            onSuccess={() => {
              navigate(ROUTES.signin)
              notificationSuccess(tf('change-password.success'), 500)
            }}
            token={token}
          />
        ) : (
          <ResetPassword />
        )}
      </Container>
    </TwoColumnLayout>
  )
}

export default PasswordResetPage
