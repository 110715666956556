import React, { useState } from 'react'
import { SolidRow, SolidError, SIZES, SolidFloatingLabelInput } from '../../Input/solid'
import LoadingButton from '../../Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import api from '../../../apis'
import PropTypes from 'prop-types'
import { notificationSuccess } from '../../../utils/notification'

type ResetPasswordProps = {
  onSuccess: () => void
}

const ResetPassword = ({ onSuccess }: ResetPasswordProps) => {
  const { t } = useTranslation('forms')

  const [isSubmitting, setSubmitting] = useState(false)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmitting(true)

    try {
      await api.auth.postResetPassword(email)

      setSubmitting(false)
      setEmail('')
      notificationSuccess(t('reset.success'))
      onSuccess()
    } catch (error) {
      const status = error.response.status

      if (status === 422) {
        setSubmitting(false)

        return error.handle422({
          email: setEmailError,
        })
      }

      error.handleDefault()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <SolidRow>
        <SolidFloatingLabelInput
          label={t('reset.email.label')}
          size={SIZES.LARGE}
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
            setEmailError('')
          }}
          isInvalid={emailError.length > 0}
          disabled={isSubmitting}
          required={true}
          type={'email'}
          placeholder={t('reset.email.placeholder')}
        />

        {emailError && <SolidError>{emailError}</SolidError>}
      </SolidRow>

      <LoadingButton
        type={'submit'}
        submitting={isSubmitting}
        className={'btn btn-primary btn-lg w-full'}
        label={t('reset.submit.label')}
      />
    </form>
  )
}

ResetPassword.propTypes = {
  onSuccess: PropTypes.func,
}

ResetPassword.defaultProps = {
  onSuccess: () => null,
}

export default ResetPassword
