import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CircleButton from '../../../components/Button/CircleButton'
import React from 'react'

const GoBackButton = ({ onClick }) => {
  return (
    <CircleButton className={'ml-5 lg:ml-8 mt-5 lg:mt-8'} onClick={onClick}>
      <FontAwesomeIcon
        className={'text-gray-600 dark:text-gray-200'}
        icon={['fal', 'chevron-left']}
      />
    </CircleButton>
  )
}

export default GoBackButton
