import React from 'react'

const Title = ({ children }) => {
  return (
    <h1 className={'text-3xl lg:text-5xl font-bold whitespace-pre-wrap mb-3 lg:mb-5'}>
      {children}
    </h1>
  )
}

export default Title
