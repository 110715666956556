import React, { useState } from 'react'
import { SolidRow, SolidError, SIZES, SolidFloatingLabelInput } from '../../Input/solid'
import LoadingButton from '../../Button/LoadingButton'
import { useTranslation } from 'react-i18next'
import api from '../../../apis'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAtom } from 'jotai'
import * as state from './state'

export type ChangePasswordProps = {
  token: string
  onSuccess: () => void
}

const ChangePassword = ({ onSuccess, token }: ChangePasswordProps) => {
  const { t } = useTranslation('forms')

  const [isSubmitting, setSubmitting] = useAtom(state.isSubmitting)

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const [currentPassword, setCurrentPassword] = useState('')
  const [currentPasswordError, setCurrentPasswordError] = useState('')
  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false)

  const requireCurrentPassword = typeof token === 'undefined'

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmitting(true)

    try {
      await api.auth.postChangePassword(token, password, currentPassword)

      setSubmitting(false)
      setPassword('')
      setCurrentPassword('')

      onSuccess()
    } catch (reqError) {
      const status = reqError.response.status

      if (status === 422) {
        setSubmitting(false)

        return reqError.handle422({
          newPassword: setPasswordError,
          currentPassword: setCurrentPasswordError,
        })
      }

      reqError.handleDefault()
    }
  }

  return (
    <form onSubmit={onSubmit}>
      {requireCurrentPassword && (
        <SolidRow>
          <SolidFloatingLabelInput
            label={t('change-password.currentPassword.label')}
            size={SIZES.LARGE}
            value={currentPassword}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCurrentPassword(e.target.value)
              setCurrentPasswordError('')
            }}
            isInvalid={currentPasswordError.length > 0}
            disabled={isSubmitting}
            required={true}
            autoComplete={'off'}
            type={isCurrentPasswordVisible ? 'text' : 'password'}
            placeholder={t('change-password.currentPassword.placeholder')}
            rightContent={
              <FontAwesomeIcon
                fixedWidth
                onClick={() => {
                  setIsCurrentPasswordVisible(!isCurrentPasswordVisible)
                }}
                className={'text-xl cursor-pointer text-gray-400 mt-1'}
                icon={['fal', isCurrentPasswordVisible ? 'eye-slash' : 'eye']}
              />
            }
          />

          {currentPasswordError && <SolidError>{currentPasswordError}</SolidError>}
        </SolidRow>
      )}

      <SolidRow>
        <SolidFloatingLabelInput
          label={t('change-password.password.label')}
          size={SIZES.LARGE}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value)
            setPasswordError('')
          }}
          isInvalid={passwordError.length > 0}
          disabled={isSubmitting}
          required={true}
          autoComplete={'off'}
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder={t('change-password.password.placeholder')}
          rightContent={
            <FontAwesomeIcon
              fixedWidth
              onClick={() => {
                setIsPasswordVisible(!isPasswordVisible)
              }}
              className={'text-xl cursor-pointer text-gray-400 mt-1'}
              icon={['fal', isPasswordVisible ? 'eye-slash' : 'eye']}
            />
          }
        />

        {passwordError && <SolidError>{passwordError}</SolidError>}
      </SolidRow>

      <LoadingButton
        type={'submit'}
        submitting={isSubmitting}
        className={'btn btn-primary btn-lg w-full'}
        label={t('change-password.submit.label')}
      />
    </form>
  )
}

ChangePassword.propTypes = {
  onSuccess: PropTypes.func,
  token: PropTypes.string,
}

ChangePassword.defaultProps = {
  onSuccess: () => null,
}

export default ChangePassword
